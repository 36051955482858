/**
 * DDLBuilder.js
 *
 * Builds a DDL from inputs;
 */

import FieldTypes from "../FieldTypes.js";

import PostgresLoadStatement from "./Dialects/PostgresLoadStatement.js";
import MySQLLoadStatement from "./Dialects/MySQLLoadStatement.js";
import RedshiftLoadStatement from "./Dialects/RedshiftLoadStatement.js";

class DDLBuilder {
  // template for the beginning of the DDL
  static DDL_TEMPLATE = "CREATE TABLE ";
  static DEFAULT_TABLE_NAME = "_put_table_name_here_";
  static PRIMARY_KEY_TEMPLATE = "PRIMARY KEY";

  static databases = {
    PostgresQL: "PostgresQL",
    MySQL: "MySQL",
    Redshift: "Amazon Redshift"
  }

  static getAllDatabases() {
    return [
      DDLBuilder.databases.PostgresQL,
      DDLBuilder.databases.MySQL,
      DDLBuilder.databases.Redshift
    ]
  }

  // Builds a DDL from the inputs
  static create(csv, dbType) {
    let columns = csv.columns;

    if (!columns) throw new Error("No columns found");

    let tableName = DDLBuilder.getTableName(csv);

    if (!tableName) tableName = DDLBuilder.DEFAULT_TABLE_NAME;
    let outputDDL = DDLBuilder.DDL_TEMPLATE + tableName + " (\n";

    // output the columns into the DDL 
    let guidCol = null;
    for (let c of columns) {
      outputDDL += "\t" + DDLBuilder.formatHeader(c.name) + " " + DDLBuilder.formatType(c.type);
      if(c.guid) {
        outputDDL += " NOT NULL";
        guidCol = c.name;
      }
      outputDDL += ",\n";
    }

    // end file
    if(guidCol) {
      outputDDL += "\tPRIMARY KEY ("+guidCol+")\n";
    } 
    else if (columns && columns.length > 0) {
      outputDDL = outputDDL.slice(0, outputDDL.length - 2); // remove last comma and return
    } else outputDDL = outputDDL.slice(0, outputDDL.length - 1); // remove last comma
    
    outputDDL += ");";

    return outputDDL;
  }

  // Formats a header to comply with SQL requirements
  // Specifically:
  // Only numbers, letters and underscores
  // Can't start with a number (POSTGRES)
  static matchAllNonValidSQLChars = /[^a-zA-Z0-9_]/g;
  static formatHeader(headerName) {
    if (!headerName) return "COLUMN_NAME";

    headerName = headerName.replace(/["']/g, ''); // remove all quotations
    headerName = headerName.replace(DDLBuilder.matchAllNonValidSQLChars, '_');
    if (/^\d/.test(headerName)) {
      headerName = '_' + headerName;
    }

    return headerName;
  }

  // Formats a type to comply with SQL requirements
  static formatType(typeName) {
    if (!typeName) return "TEXT";

    switch (typeName) {
      case FieldTypes.INTEGER: return "INTEGER";
      case FieldTypes.DECIMAL: return "DECIMAL";
      case FieldTypes.TEXT: return "TEXT";
      case FieldTypes.DATE: return "DATE";
      case FieldTypes.DATETIME: return "DATETIME";
      case FieldTypes.EMAIL: return "TEXT";
      case FieldTypes.BOOLEAN: return "BOOLEAN";
      case FieldTypes.CURRENCY: return "TEXT";
      case FieldTypes.EMPTY: return "VARCHAR(1)";
      default: return "TEXT";
    }
  }

  // Extracts a table name from a csv object
  static getTableName(csv) {
    if(!csv.fileName) return DDLBuilder.DEFAULT_TABLE_NAME;

    let tableName = csv.fileName.replace('.csv', '');
    tableName = tableName.replace(DDLBuilder.matchAllNonValidSQLChars, '_');
    if (/^\d/.test(tableName)) {
      tableName = '_' + tableName;
    }

    return tableName;
  }

  // Builds the statement to create the table for a given csv object
  static generateLoadingCommand(csv, dbType) {
    const tableName = DDLBuilder.getTableName(csv);
    switch(dbType) {
      case DDLBuilder.databases.PostgresQL: return <PostgresLoadStatement csv={csv} tableName={tableName} headerFormatter={DDLBuilder.formatHeader}/>;
      case DDLBuilder.databases.MySQL: return <MySQLLoadStatement csv={csv} tableName={tableName} headerFormatter={DDLBuilder.formatHeader}/>;
      case DDLBuilder.databases.Redshift: return <RedshiftLoadStatement csv={csv} tableName={tableName} headerFormatter={DDLBuilder.formatHeader}/>;
 
      default: return <></>;
    }
  }

  static getDDLName(csv) {
    return DDLBuilder.getTableName(csv) + ".ddl"
  }
}

export default DDLBuilder;