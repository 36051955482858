class CSVBuilder {
    // Builds a text string for the provided CSV
    static build(csv) {
        if (!csv || csv.rows.length === 0) {
            return ''; // Return an empty string for an empty or non-array input.
        }

        const csvRows = csv.rows.map(row => {
            return CSVBuilder.rowToString(row, csv.columns);
        });

        if(csv.columns) {
            csvRows.unshift(CSVBuilder.headersToString(csv.columns));
        }

        return csvRows.join('\n');
    }

    // Turns an array into an escaped CSV string
    static headersToString(cols) {
        let names = cols.map(c => c.name);
        return this.rowToString(names, cols); 
    }

    // Turns an array into an escaped CSV string
    static rowToString(row, columns) {
        let str = '';
        for(let i in row) {
            if(!columns[i].hidden) {
                let val = row[i] + '';
                str+= '"' + val.replace(/"/g, '""') + '",';
            }
        }
        str = str.slice(0, -1);

        return str; 
    }
}

export default CSVBuilder;