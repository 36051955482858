import CSVIssue from "../../CSVIssue/CSVIssue";
import TemplateColDetector from "./TemplateColDetector";

class MissingValuesDetector extends TemplateColDetector {
    constructor() {
        super();
        this.detectorType = CSVIssue.MISSING_VALUES;
    }

    test(value, expectedType) {
        return (!value || value === '');
    }
}

export default MissingValuesDetector;