function ColumnList({csv, headerFormatter}) {
    if (csv.columns && csv.columns.length > 0) {
        return (
            <>
            {csv.columns.map((col,i) => 
                <span key={col.name}>{headerFormatter(col.name)}{(i == (csv.columns.length - 1))? '' : ','}</span>
            )}
            </>
        );

    } else return <></>;
}

export default ColumnList;