// components
import LoginPage from './LoginPage/LoginPage.js';
import FixingPage from './FixingPage/FixingPage.js';
import UploadPage from './UploadPage/UploadPage.js';
import ProcessingPage from './ProcessingPage/ProcessingPage.js';
import DownloadPage from './DownloadPage/DownloadPage.js';
import Header from '../Components/Header/Header.js';

import { useContext } from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

// contexts
import { UserContext } from "../Contexts/UserContext";

function PageContainer() {
    const [user, setUser] = useContext(UserContext);

    return (
        <BrowserRouter>
            { user ? <Header/> : <></> }
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    <div className="col py-3">
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/upload" element={<UploadPage />} />
                            <Route path="/fix" element={<FixingPage />} />
                            <Route path="/processing" element={<ProcessingPage />} />
                            <Route path="/download" element={<DownloadPage />} />
                            <Route path="/" element={<UploadPage />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default PageContainer;