import { useContext } from "react";

import { CSVContext } from "../../Contexts/CSVContext.js";
import DateChart from "../../Components/Widgets/DateChart.js";

function MissingDatesModal({ issue }) {
  return (
    <div className="container-fluid modalStyling">
      <div className="row">
        <div className="col-12 align-middl m-2">
          <h2 id="fix-modal-title">Missing Dates in {issue.column}</h2>
          <p id="fix-modal-description">
            There are gaps in the data for column {issue.column} where there are no rows for certain dates:
          </p>
          <DateChart data={issue.metrics.histo}/>
        </div>
      </div>
    </div>
  );
}

export default MissingDatesModal;