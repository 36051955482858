function NotificationModal({ notify, toClose }) {
  return (
    <div className="container-fluid notificationModalStyling">
      <div className="row">
        <div className="col-12 align-middl m-2 text-center">
          <h2 id="notification-modal-title">File Truncated</h2>
          <p id="notification-modal-description">
            Your CSV file is larger than the allowed limit for our free product. Your file has been truncated,
            so not all rows will appear here or in the output.
          </p>
          <button type="button" className="btn btn-success" onClick={() => toClose()}>Proceed</button>
        </div>
      </div>
    </div>
  );
}

export default NotificationModal;