const JSChardet = require("jschardet")

function decodeBinaryToText(bin) {
    let encoding = "UTF-8";
    let details = JSChardet.detect(bin);
    if(details && details.encoding) encoding = details.encoding;
    let decoder = new TextDecoder(encoding); 

    let str = decoder.decode(bin); 

    return [encoding, str];
}

export default decodeBinaryToText;