import FieldTypes from "../FieldTypes";
import CSVIssueMap from "../CSVIssueMap/CSVIssueMap";

import BadTypeDetector from "./Detectors/BadTypeDetector";
import MissingValuesDetector from "./Detectors/MissingValuesDetector";
import MissingDatesDetector from "./Detectors/MissingDatesDetector";
import MissingColumnsDetector from "./Detectors/MissingColumnsDetector";
import GUIDDetector from "./Detectors/GUIDDetector";

class CSVAnalyzer {
    constructor() {
        this.colPipeline = [];
        this.colPipeline.push({d: new BadTypeDetector()});
        this.colPipeline.push({d: new MissingValuesDetector()});
        this.colPipeline.push({type: FieldTypes.DATETIME, d: new MissingDatesDetector()});
        this.colPipeline.push({type: FieldTypes.SQL_DATE, d: new MissingDatesDetector()});
        this.colPipeline.push({type: FieldTypes.INTEGER, d: new GUIDDetector});
        this.colPipeline.push({type: FieldTypes.TEXT, d: new GUIDDetector});

        this.rowPipeline = [];
        this.rowPipeline.push({d: new MissingColumnsDetector()})
    }

    analyze(csv) {
        let fixedIssues = new CSVIssueMap({});
        let openIssues = new CSVIssueMap({});

        //TODO: Look at the issues themselves to decide if they go in open or fixed

        // apply all analyzers to this row
        this.rowPipeline.map((p) => {
            let found = p.d.analyze(csv);
            fixedIssues.addAll(found);
            return p;
        });

        // process column by column
        for(let col in csv.columns) {
            const colType = csv.columns[col].type;

            // apply all analyzers to this column (if the type matches)
            this.colPipeline.map((p) => {
                if(!p.type || p.type === colType) {
                    let found = p.d.analyze(csv, col);
                    openIssues.addAll(found);
                }
                return p;
            });
        }

        return {
            fixed: fixedIssues,
            open: openIssues
        };
    }

    analyzeCol(csv, col) {
        let fixedIssues = new CSVIssueMap({});
        let openIssues = new CSVIssueMap({});

        const colType = csv.columns[col].type;

        // apply all analyzers to this column (if the type matches)
        this.colPipeline.map((p) => {
            if(!p.type || p.type === colType) {
                let found = p.d.analyze(csv, col);
                openIssues.addAll(found);
            }
            return p;
        });

        return {
            fixed: fixedIssues,
            open: openIssues
        };
    }    
}

export default CSVAnalyzer;