import FieldTypes from "../FieldTypes.js";

class CSV {
    constructor() {
      this.fileName = "";
      this.size = 0;
      this.encoding = "";
      this.columns = [];
      this.rows = [];
    }

    testData() {
        this.fileName = "test_data.csv";
        this.size = 7682;
        this.encoding = "UTF-8";
        this.columns = [
          {name: "ID", type: FieldTypes.INTEGER}, 
          {name:"First", type: FieldTypes.INTEGER}, 
          {name:"Last", type: FieldTypes.INTEGER}, 
          {name:"Email", type: FieldTypes.INTEGER}, 
          {name:"Active", type: FieldTypes.INTEGER}];
        this.rows = [
            [0, "Sean", "Test", "sean@test.com", "true"],
            [1, "Beth", "1.0", "Beth@test.com", "true"],
            [2, "Joe", "Smith", "", "false"],
            [3, "Frank", "Tart", "frank@tarty.com", "true"]
        ]
    }

    static buildColumn(value, type) {
      let t = FieldTypes.TEXT;
      if(type) t = type;
      return {name: value, type: t, hidden: false, guid: false};
    }
  }

  export default CSV;