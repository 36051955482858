import CSV from "../CSV/CSV.js"
import Typer from "../Typer/Typer.js"

class CSVParser {
    static QUOTATION_CHARACTER = '"';
    static DELIMITER = ',';

    // Safely splits a string with a given delimiter, respecting quotation segments where the delimiter should be ignored
    static safeSplit(input, delimiter) {
        let segments = [];
        if (input.length === 0) return segments;

        let accumulator = "";
        let escape_status = false;
        for (const c of input) {
            if (c === CSVParser.QUOTATION_CHARACTER) {
                escape_status = !escape_status; // toggle whether we are escaped
            } else if (c === delimiter) {
                if (escape_status) {
                    accumulator += c;
                } else {
                    segments.push(accumulator);
                    accumulator = "";
                }
            } else {
                accumulator += c;
            }
        }
        segments.push(accumulator);

        return segments.map(x => x.trim());
    }


    // Parse a raw text blob into a CSV object
    static parse(rawFile) {
        const spreadsheet = new CSV();

        spreadsheet.fileName = rawFile.name;
        spreadsheet.size = rawFile.size;
        spreadsheet.encoding = rawFile.encoding;

        if (rawFile.contents) {
            // turn blob into lines
            let splitLines = rawFile.contents.split(/\r?\n/);
            let rows = splitLines.map((r) => {
                // turn lines into rows
                let trimmedRows = r.trim();
                let splitCells = CSVParser.safeSplit(trimmedRows, CSVParser.DELIMITER);

                // remove any whitespace in each cell
                let trimmedCells = splitCells.map((c) => c.trim());

                return trimmedCells;
            });

            spreadsheet.columns = rows[0].map((h) => {return CSV.buildColumn(h)});
            spreadsheet.rows = rows.slice(1);

            // see if last row has anything in it
            if (spreadsheet.rows && spreadsheet.rows.length > 0 
                && (spreadsheet.rows[spreadsheet.rows.length - 1].length === 0 
                    || spreadsheet.rows[spreadsheet.rows.length - 1][0] === '')) {
                spreadsheet.rows.pop();
            }

            // if this is a truncated file, remove the last row since it's almost guaranteed to be incomplete
            if(rawFile.truncated) {
                spreadsheet.rows.pop();
            }
        } 

        // detect and set types
        let types = Typer.detect(spreadsheet.rows);
        for(let i in spreadsheet.columns) {
            spreadsheet.columns[i].type = types[i];
        }

        return spreadsheet;
    }
}

export default CSVParser;