import CSVIssue from "../../CSVIssue/CSVIssue";
import Typer from "../../Typer/Typer";
import TemplateColDetector from "./TemplateColDetector";


class BadTypeDetector extends TemplateColDetector {
    constructor() {
        super();
        this.detectorType = CSVIssue.BAD_TYPE;
    }

    test(value, expectedType) {
        if(value === "" || value === null) return false; // if there is no value it's not a type problem
        let type = Typer.identify(value);
        return  (!type || expectedType !== type);
    }
}

export default BadTypeDetector;