function IssueCard({ issue, fixer, ignorer }) { 

  return (
    <li className="list-group-item">
        <p className="card-text">
          {issue.column ? <><strong>{issue.cells.length}</strong> {issue.type} errors found in column: <strong>{issue.column}</strong></> : <>{issue.type}</>}
        </p>
        <a href="#" className="card-link btn btn-outline-primary btn-sm me-1" onClick={() => fixer(issue)}>Fix</a>
        <a href="#" className="card-link btn btn-outline-secondary btn-sm" onClick={() => ignorer(issue)}>ignore</a>
      </li>
  );
}

export default IssueCard;