import { useContext } from "react";

// contexts
import { CSVContext } from "../../Contexts/CSVContext.js";
import { IssuesContext } from "../../Contexts/IssuesContext.js";

// utils
import FieldTypes from "../../Util/FieldTypes.js";
import CSVIssueMap from "../../Util/CSVIssueMap/CSVIssueMap.js";
import CSVAnalyzer from "../../Util/CSVAnalyzer/CSVAnalyzer.js";

function TypeSelector({ selectedIndex }) {
    const [currentCSV, setCurrentCSV] = useContext(CSVContext);  
    const [currentIssues, setCurrentIssues] = useContext(IssuesContext);  
    const analyzer = new CSVAnalyzer();
   
    const changeType = (newType) => {
        let csv = {...currentCSV};
        csv.columns[selectedIndex].type = newType;
        setCurrentCSV(csv);

        let colName = csv.columns[selectedIndex].name;

        // Look for any new issues
        let newIssues = analyzer.analyzeCol(csv, selectedIndex);

        // Remove any issues for this column from the existing issues to avoid duplicates
        let cleanedIssues = {
            fixed: CSVIssueMap.clean(currentIssues.fixed, colName),
            open: CSVIssueMap.clean(currentIssues.open, colName)
        }

        // Combine the two lists
        let fixedIssues = CSVIssueMap.combineMaps([cleanedIssues.fixed, newIssues.fixed]);
        let openIssues = CSVIssueMap.combineMaps([cleanedIssues.open, newIssues.open]);
        setCurrentIssues({
            open: openIssues,
            fixed: fixedIssues
        });
    }

    const toggleHide = () => {
        let csv = {...currentCSV};
        csv.columns[selectedIndex].hidden = !csv.columns[selectedIndex].hidden;
        setCurrentCSV(csv);
    }

    const toggleGUID = () => {
        let csv = {...currentCSV};
        csv.columns[selectedIndex].guid = !csv.columns[selectedIndex].guid;
        setCurrentCSV(csv);
    }

    const deleteColumn = () => {
        let csv = {...currentCSV};
        csv.columns.splice(selectedIndex, 1);
        for(let row of csv.rows) {
            row.splice(selectedIndex, 1);
        }
        setCurrentCSV(csv);
    }


    return (
        <div className="dropdown">
            <a className="dropdown-toggle link-secondary" href="#" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {currentCSV.columns[selectedIndex].type}
            </a>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {FieldTypes.allTypes.map((type, i) => (
                    <li key={i}><a className="dropdown-item" href="#" onClick={() => changeType(type)} key={i}>{type}</a></li>
                ))}
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#" onClick={() => toggleGUID()}>{ currentCSV.columns[selectedIndex].guid ? <i className="bi bi-check"></i> : <></>} GUID</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#" onClick={() => toggleHide()}>{ currentCSV.columns[selectedIndex].hidden ? "Unhide" : "Hide"} Column</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => deleteColumn()}>Delete Column</a></li>
            </ul>
            
        </div>
    );
}

export default TypeSelector;