import { useContext, useCallback, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import "./UploadPage.css"
import Modal from '@mui/material/Modal';

// contexts
import { RawFileContext } from "../../Contexts/RawFileContext.js";
import WarningModal from "./WarningModal.js";

// utils 
import useVerifyUser from "../../Session/VerifyUser.js";

// Renders the CSV Upload view
function UploadView() {
  const [currentFile, setCurrentFile] = useContext(RawFileContext);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  // Verify that we should be here
  useVerifyUser();

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  }
  const closeNotificationModal = () => { setOpenNotificationModal(false); }

  // handle the uploaded file
  const navigate = useNavigate();
  const handleFile = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const filename = acceptedFiles[0].name;

      if (filename.endsWith(".csv")) {
        setCurrentFile({
          handle: acceptedFiles[0],
          name: acceptedFiles[0].name,
          size: acceptedFiles[0].size
        });

        navigate("/processing");
      } else {
        setOpenNotificationModal(true);
      }
    }
  }, []);

  const {
    acceptedFiles,
    fileRejections,
    open,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    onDrop: handleFile
  });

  return (
    <>
      <div className="container col-xl-10 col-xxl-8 py-5">
        <div className="row align-items-center g-lg-5 py-5">
          <div className="col-lg-7 text-center">
            <h1 className="display-4 fw-bold lh-1 mb-3">Automatically Fix Any <br />CSV File</h1>
            <p className="lead fs-4">Just upload any CSV file and let AI fix all of the problems, giving you a perfectly clean file.</p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <div {...getRootProps()} className="p-2 border rounded-3 bg-light">
              <input {...getInputProps()} />
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <div className="card d-flex align-items-center" style={{ height: "200px" }}>
                    <div className="card-body d-flex align-items-center">
                      <h1 className="brand-font">Drop a CSV file here to start</h1>
                      <h1><i className="bi bi-upload"></i></h1>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12  text-center">
                    or <a href="#" onClick={open} className="link-secondary">select a file</a>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="b-example-divider"></div>

      <div className="px-4 pt-5 my-5 text-center border-bottom">
        <h1 className="display-6 fw-bold text-secondary">No More CSV Headaches</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Find and solve all problems with a CSV file in minutes, including file corruption, data quality and data integrity issues. Download a perfectly clean file, ready for use in other applications.</p>
        </div>
        <div className="overflow-hidden" style={{ maxHeight: "30vh" }}>
          <div className="container px-5">
            <img src="img/hero.png" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy" />
          </div>
        </div>
      </div>
      <div className="b-example-divider"></div>

      <div className="bg-dark text-secondary px-4 py-5 text-center">
        <div className="py-5">
          <h1 className="display-6 fw-bold text-white">Powerful Tools for Data Engineering</h1>
          <div className="col-lg-6 mx-auto">
            <p className="fs-5 mb-4">Automatically prepare your CSV for uploading into a database, including generation of DDL files to create the necessary SQL tables.</p>
          </div>
          <div className="overflow-hidden" style={{ maxHeight: "30vh" }}>
            <div className="container px-5">
              <img src="img/data_hero.png" className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy" />
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openNotificationModal}
        onClose={handleCloseNotificationModal}
        aria-labelledby="notification-modal-title"
        aria-describedby="notification-modal-description"
      >
        <div><WarningModal toClose={closeNotificationModal} /></div>
      </Modal>
    </>
  );
}

export default UploadView;