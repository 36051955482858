import React, { createContext, useState } from "react";

// Context for storing the raw CSV data.
const CSVContext = createContext({});
const CSVContextProvider = ({ children }) => {
  const [currentCSV, setCurrentCSV] = useState({});

  return (
    <CSVContext.Provider value={[currentCSV, setCurrentCSV]}>
      {children}
    </CSVContext.Provider>
  );
};

export { CSVContext, CSVContextProvider };