import ColumnList from "./ColumnList";

function RedshiftLoadStatement({ csv, tableName, headerFormatter }) { 
    return (
      <>
copy {tableName} from 's3://&lt;your-bucket-name&gt;/{csv.fileName}'<br/> 
credentials 'aws_iam_role=arn:aws:iam::&lt;aws-account-id&gt;:role/&lt;role-name&gt;'<br/>
csv;
      </>
    );
  }
  
  export default RedshiftLoadStatement;