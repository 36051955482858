import logo from './logo.svg';
import './App.css';


import PageContainer from './Pages/PageContainer';
import { UserContext, UserContextProvider } from "./Contexts/UserContext.js";
import { CSVContext, CSVContextProvider } from './Contexts/CSVContext';
import { IssuesContext, IssuesContextProvider } from './Contexts/IssuesContext';
import { RawFileContext, RawFileContextProvider } from './Contexts/RawFileContext';

function App() {
  return (
    <>
    <UserContextProvider>
      <RawFileContextProvider>
        <CSVContextProvider>
          <IssuesContextProvider>
            <PageContainer />
          </IssuesContextProvider>
        </CSVContextProvider>
      </RawFileContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
