import { useContext } from "react";
import { CSVContext } from "../../Contexts/CSVContext.js";

// utils
import FieldTypes from "../../Util/FieldTypes.js";

// Interactive tools for correcting issues with a cell with a BadType error
function BadTypePopover({ issue, cell, actions }) {
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);

  // actions custom to this error type
  const specialActions = {
    fixType: () => {
      setCurrentCSV((prevCSV) => {
        const value = prevCSV.rows[cell.row][cell.col];
        if (issue.dataType === FieldTypes.DECIMAL) {
          prevCSV.rows[cell.row][cell.col] = value + ".0";
        } else if (issue.dataType === FieldTypes.INTEGER) {
          prevCSV.rows[cell.row][cell.col] = value.substring(0, value.indexOf('.'));
        }
        return prevCSV;
      });

      actions.ignore();
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-9 align-middl m-2">
          <strong>Problem:</strong> {issue.type}
        </div>
        <div className="col-2 mt-2 p-0 text-end">
          <a href="#" onClick={actions.close}><i className="bi bi-x-lg"></i></a>
        </div>
      </div>

      <div className="row">
        <div className="col-12 align-middl m-2">
          <div><strong>Fixes:</strong>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.deleteRow}>Delete Row</button>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.deleteValue}>Delete Cell</button>
            {issue.dataType == FieldTypes.DECIMAL || issue.dataType == FieldTypes.INTEGER ?
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={specialActions.fixType}>Fix Type</button>
            : <></>}
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.ignore}>Ignore</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BadTypePopover;