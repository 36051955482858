import { useState, useContext, useEffect } from "react";
import {
  useNavigate
} from "react-router-dom";

// contexts
import { RawFileContext } from "../../Contexts/RawFileContext.js";
import { CSVContext } from "../../Contexts/CSVContext.js";
import { IssuesContext } from "../../Contexts/IssuesContext.js";

// Progress Bar (Material UI)
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

// utils
import CSVAnalyzer from "../../Util/CSVAnalyzer/CSVAnalyzer.js";
import CSVParser from "../../Util/CSVParser/CSVParser.js";
import TextUtil from "../../Util/TextUtil/TextUtil.js";

// View for processing files
const TOTAL_STEPS = 100;
const MAXIMUM_FILE_SIZE = 50000000;

function ProcessingView() {
  const [currentFile, setCurrentFile] = useContext(RawFileContext);
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);
  const [currentIssues, setCurrentIssues] = useContext(IssuesContext);

  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();


  // load the file
  const loadFile = (nextStep) => {
    console.log("Loading file...")
    const reader = new FileReader()
    let truncated = false;

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    // Event listener for progress updates
    reader.onprogress = function (event) {
      if (event.lengthComputable) {
        // Calculate the percentage of completion
        const loadProgress = Math.ceil((event.loaded / event.total) * 100);
        setProgress(loadProgress);
        console.log(`Progress: ${loadProgress}%`);
      }
    };
    reader.onload = () => {
      const binaryStr = reader.result;
      const [encoding, str] = TextUtil(binaryStr);

      const newFile = {
        ...currentFile,
        encoding: encoding,
        contents: str,
        truncated: truncated
      };
      setCurrentFile(newFile);

      // next step
      nextStep(newFile);
    }

    if (currentFile.size > MAXIMUM_FILE_SIZE) {
      console.log("File too large, truncating it...");
      truncated = true;
      const blob = currentFile.handle.slice(0, MAXIMUM_FILE_SIZE);
      reader.readAsArrayBuffer(blob);
    } else {
      console.log("File size is fine!");
      reader.readAsArrayBuffer(currentFile.handle)
    }
  }

  const parseFile = (newFile, nextStep) => {
    console.log("Parsing file...")
    // parse file
    let rawfile = CSVParser.parse(newFile);
    const analyzer = new CSVAnalyzer();
    let results = analyzer.analyze(rawfile);
    let issues = results.open;
    let fixed = results.fixed;

    setCurrentCSV(rawfile);
    setCurrentIssues({
      open: issues,
      fixed: fixed
    });

    nextStep();
  }

  // Do processing with updates
  useEffect(() => {
    loadFile((newFile) => {
      parseFile(newFile, () => {
        console.log("All done...")
        if (newFile.truncated) {
          navigate("/fix?n=t");
        } else navigate("/fix");
      })
    })
  }, [currentFile]);

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col-12 mt-5 text-center h1 brand-font">
          Processing <div className="font-monospace">{currentCSV.fileName}</div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-10 offset-1  text-center">
          <LinearProgress variant="determinate" value={progress} sx={{
            height: 30
          }} />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 text-center small">
          Estimated time remaining: {TOTAL_STEPS - progress} seconds
        </div>
      </div>
    </div>
  );
}

export default ProcessingView;