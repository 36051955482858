// Modals
import MissingDatesModal from "./MissingDatesModal.js";

// utils
import CSVIssue from "../../Util/CSVIssue/CSVIssue.js";

// Interactive tools for correcting issues with a cell
function FixModal({ issue, toClose }) {
  const actions = {
    // ignores this issue
    ignore: () => {
      toClose(true);
    }
  }

  if(issue) {
    switch(issue.type) {
      case CSVIssue.MISSING_DATES: return (<MissingDatesModal issue={issue} actions={actions} />);
      default: return <></>;
    }
  } else return <></>;
}

export default FixModal;