import CSVIssue from "../../CSVIssue/CSVIssue";

class TemplateRowDetector {

    analyze(csv) {
        let issue = new CSVIssue({ type: this.detectorType });

        // look for problems
        for (let row in csv.rows) {
            if (this.test(csv, row)) {
                issue.cells.push({ row: parseInt(row)});
            } 
        }

        if (issue.cells && issue.cells.length > 0) {
            
            return [issue];
        } else return null;
    }

    test(csv, row) {
        throw new Error("Detector did not implement test()");
    }
}

export default TemplateRowDetector;