class FieldTypes {
    static INTEGER = "Integer";
    static DECIMAL = "Decimal";
    static TEXT = "Text";
    static DATETIME = "Datetime";
    static SQL_DATE = "SQL Date";
    static EMAIL = "Email";
    static BOOLEAN = "Boolean";
    static CURRENCY = "Currency";
    static EMPTY = "Empty";

    static allTypes = [
        FieldTypes.INTEGER,
        FieldTypes.DECIMAL,
        FieldTypes.TEXT,
        FieldTypes.DATETIME,
        FieldTypes.SQL_DATE,
        FieldTypes.EMAIL,
        FieldTypes.BOOLEAN,
        FieldTypes.CURRENCY,
        FieldTypes.EMPTY
    ]
}

export default FieldTypes;