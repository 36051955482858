import { useContext } from "react";

import { CSVContext } from "../../Contexts/CSVContext.js";

// Interactive tools for correcting issues with a cell with a MissingValue error
function MissingValuePopover({ issue, cell, actions }) {
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);

  // actions custom to this error type
  const specialActions = {
    addDefault: () => {
        setCurrentCSV((prevCSV) => {
          prevCSV.rows[cell.row][cell.col] = "N/A";
          return prevCSV;
        });

        actions.ignore();
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-9 align-middl m-2">
          <div><strong>Problem:</strong> {issue.type}</div>
        </div>
        <div className="col-2 mt-2 p-0 text-end">
          <a href="#" onClick={actions.close}><i className="bi bi-x-lg"></i></a>
        </div>
      </div>

      <div className="row">
        <div className="col-12 align-middl m-2">
          <div><strong>Fixes:</strong>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.deleteRow}>Delete Row</button>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.deleteValue}>Delete Cell</button>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={specialActions.addDefault}>Add Default</button>
            <button type="button" className="btn btn-outline-secondary btn-sm mx-1" onClick={actions.ignore}>Ignore</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MissingValuePopover;