class CSVIssueMap {
    constructor({ issues = new Map(), types = [] }) {
        this.issueMap = issues;
        this.types = types;
        this.count = 0;
    }

    addAll(issues) {
        if (issues) {
            for (let i of issues) {
                this.add(i);
            }
        }
    }

    add(issue) {
        if (issue) {
            if (!this.issueMap.has(issue.type)) {
                this.issueMap.set(issue.type, [issue]);
                this.types.push(issue.type);
            } else {
                this.issueMap.get(issue.type).push(issue);
            }
            this.count++;
        }
    }

    getAllByType(type) {
        return this.issueMap.get(type);
    }

    getAll() {
        let result = [];
        for (let type of this.types) {
            result.push({
                type: type,
                issues: this.issueMap.get(type)
            })
        }
        return result;
    }

    getAllIssues() {
        let result = [];
        for (let type of this.types) {
            result = result.concat(this.issueMap.get(type));
        }
        return result;
    }

    getCount() {
        return this.count;
    }

    remove(issue) {
        if (this.issueMap.has(issue.type)) {
            let filtered = this.issueMap.get(issue.type).filter((e) => e.id !== issue.id);
            if (filtered.length === 0) {
                this.types = this.types.filter((t) => (t !== issue.type));
                this.issueMap.delete(issue.type);
            } else {
                this.issueMap.set(issue.type, filtered);
            }
            this.count--;
        }
    }

    // Combines two issue maps into one
    static combineMaps(issueMaps) {
        let newMap = new CSVIssueMap({});

        for(let imap of issueMaps) {
            newMap.addAll(imap.getAllIssues());
        }

        return newMap;
    }

    // Cleans an issueMap of any issues for a given column and returns a new map
    static clean(originalMap, colName) {
        let newMap = new CSVIssueMap({});

        for(let issue of originalMap.getAllIssues()) {
            if(!issue.column || issue.column !== colName) {
                newMap.add(issue);
            }
        }

        return newMap;
    }
}

export default CSVIssueMap;