import CSVIssue from "../../CSVIssue/CSVIssue";
import TemplateColDetector from "./TemplateColDetector";

const THRESHOLD = 0.2;
class MissingDatesDetector extends TemplateColDetector {
    constructor() {
        super();
        this.detectorType = CSVIssue.MISSING_DATES;
    }

    analyze(csv, col) {
        let issues = [];

        // Create the timeline
        let dateMap = new Map();
        for (let row in csv.rows) {
            let value = csv.rows[row][col];
            // insert into the timeline
            const thisDate = this.parseDate(value);
            if(dateMap.has(thisDate)) dateMap.set(thisDate, dateMap.get(thisDate)+1);
            else dateMap.set(thisDate, 1);
        }

        // Sort the timeline of unique dates
        let timeline = [ ...dateMap.keys() ];
        timeline.sort(function(a, b) {
            // Subtract the dates to get a value that is either negative, positive, or zero
            return a - b;
        });

        // TODO: It might be just that a month is missing, not just scattered days
        // Determine what dates are missing
        let missingDates = this.findMissingDates(timeline);

        if(missingDates && missingDates.length > 0) {
            // The dates might just be sparse. Let's make sure they are dense enough
            const ratio = missingDates.length / timeline.length;
            console.log("Found missing date ratio of:" + ratio);
            if(ratio > 0 && ratio < THRESHOLD) {

                let missingDatesValues = [];
                for(let md of missingDates) {
                    missingDatesValues.push([md, 0]);
                }
                let fullDates = [...dateMap.entries(), ...missingDatesValues];
                fullDates.sort(function(a, b) {
                    return a[0] - b[0];
                });


                let issue = new CSVIssue({ type: this.detectorType });
                issue.column = csv.columns[col].name;
                issue.dataType = csv.columns[col].type;
                issue.data = missingDates;
                issue.metrics = {
                    histo: fullDates
                }
                issue.cells.push({ col: parseInt(col) });

                issues.push(issue);
            }
        }

        if(issues.length > 0) {
            return issues;
        } else return null;
    }

    // extracts a date from a field, removing any time of day information
    parseDate(value) {
        let index = value.indexOf(' ');
        if(index === -1) {
            index = value.indexOf('T');
        }

        if (index !== -1) {
          return new Date(value.substring(0, index));
        } else return new Date(value);
    }

    // finds missing dates in a sorted array of dates
    findMissingDates(sortedDates) {
        const missingDates = [];
      
        for (let i = 0; i < sortedDates.length - 1; i++) {
          const currentDate = sortedDates[i];
          const nextDate = new Date(currentDate);
          nextDate.setDate(currentDate.getDate() + 1);
      
          if (nextDate < sortedDates[i + 1]) {
            while (nextDate < sortedDates[i + 1]) {
              missingDates.push(new Date(nextDate));
              nextDate.setDate(nextDate.getDate() + 1);
            }
          }
        }
      
        return missingDates;
      }
}

export default MissingDatesDetector;