import CSVIssue from "../../CSVIssue/CSVIssue";
import TemplateRowDetector from "./TemplateRowDetector";

class MissingColumnsDetector extends TemplateRowDetector {
    constructor() {
        super();
        this.detectorType = CSVIssue.MISSING_COLUMNS;
    }

    test(csv, row) {
        const result = csv.rows[row].length !== csv.columns.length;

        if(csv.rows[row].length < csv.columns.length) {
            const extra = new Array(csv.columns.length - csv.rows[row].length).fill('');
            csv.rows[row] = csv.rows[row].concat(extra);
        }

        return result;
    }
}

export default MissingColumnsDetector;