import { useContext } from "react";

// contexts
import { CSVContext } from "../../Contexts/CSVContext.js";

import BadTypePopover from "./BadTypePopover.js";
import MissingValuePopover from "./MissingValuePopover.js";

// utils
import CSVIssue from "../../Util/CSVIssue/CSVIssue.js";

// Interactive tools for correcting issues with a cell
function FixPopover({ issue, cell, toClose }) {
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);

  const actions = {
    // deletes the row for this cell
    deleteRow: () => {
      setCurrentCSV((prevCSV) => {
        delete prevCSV.rows[cell.row];
        return prevCSV;
      });

      toClose(true);
    },

    // Deletes the value from this cell
    deleteValue: () => {
      setCurrentCSV((prevCSV) => {
        prevCSV.rows[cell.row][cell.col] = '';
        return prevCSV;
      });

      toClose(true);
    },

    // ignores this cell
    ignore: () => {
      toClose(true);
    },

    // closes the popover
     close: () => {
      toClose(false);
    }
  }

  if(issue) {
    switch(issue.type) {
      case CSVIssue.BAD_TYPE: return (<BadTypePopover issue={issue} cell={cell} actions={actions} />);
      case CSVIssue.MISSING_VALUES: return (<MissingValuePopover issue={issue} cell={cell} actions={actions} />);
      default: return <></>;
    }
  } else return <></>;
}

export default FixPopover;