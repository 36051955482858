import ColumnList from "./ColumnList";

function PostgresLoadStatement({ csv, tableName, headerFormatter }) { 

    return (
      <>
COPY {tableName}(<ColumnList csv={csv} headerFormatter={headerFormatter}/>)<br/>
FROM '{csv.fileName}'<br/>
DELIMITER ','<br/>
CSV HEADER;
      </>
    );
  }
  
  export default PostgresLoadStatement;