"use strict";
/**
 * Typer.js
 *
 * Type checking utilities for arrays of values;
 */

import FieldTypes from "../FieldTypes.js"

// Utility for detecting types 
class Typer {
  static LINE_LIMIT = 100;
  static detect(rows) {
    if (!rows || rows.length == 0) return [];

    let columns = Array.from({ length: rows[0].length }, () => []);
    let limit = rows.length;
    if (rows.length > Typer.LINE_LIMIT) limit = Typer.LINE_LIMIT;
    // turn first N rows into columns
    for (let row = 0; row < limit; row++) {
      for (let column = 0; column < columns.length; column++) {
        if(rows[row][column] && rows[row][column] !== "") {
          columns[column].push(rows[row][column])
        }
      }
    }

    // Look through columns and identify the type
    let types = new Array(rows[0].length);
    for (let col in columns) {
      types[col] = FieldTypes.TEXT;
      if (Typer.isEmpty(columns[col])) {
        types[col] = FieldTypes.EMPTY;
      } else if (Typer.isDate(columns[col])) {
        types[col] = FieldTypes.DATETIME;
      } else if (Typer.isSQLDate(columns[col])) {
        types[col] = FieldTypes.SQL_DATE;
      } else if (Typer.isInteger(columns[col])) {
        types[col] = FieldTypes.INTEGER;
      } else if (Typer.isBoolean(columns[col])) {
        types[col] = FieldTypes.BOOLEAN;
      } else if (Typer.isCurrency(columns[col])) {
        types[col] = FieldTypes.CURRENCY;
      } else if (Typer.isDecimal(columns[col])) {
        types[col] = FieldTypes.DECIMAL;
      } else {
        types[col] = FieldTypes.TEXT;
        // TODO: This should limit the field size to the required size
        //types[col] = "VARCHAR(" + Typer.maxChars(columns[col]) + ")";
      }
    }

    return types;
  }

  static identify(value) {
    if (value == null || value == "") return FieldTypes.EMPTY;

    // Look through columns and identify the type
    let valArray = [value];
    if (Typer.isEmpty(valArray)) {
      return FieldTypes.EMPTY;
    } else if (Typer.isDate(valArray)) {
      return FieldTypes.DATETIME;
    } else if (Typer.isSQLDate(valArray)) {
      return FieldTypes.SQL_DATE;
    } else if (Typer.isInteger(valArray)) {
      return FieldTypes.INTEGER;
    } else if (Typer.isBoolean(valArray)) {
      return FieldTypes.BOOLEAN;
    } else if (Typer.isCurrency(valArray)) {
      return FieldTypes.CURRENCY;
    } else if (Typer.isDecimal(valArray)) {
      return FieldTypes.DECIMAL;
    } else {
      // Must be Text
    }

    return FieldTypes.TEXT;
  }

  // Return true if the test passes the threshold
  static THRESHOLD = 0.75;
  static validateTestResults(testResults, original) {
    return ((testResults.filter(Boolean).length / original.length) > this.THRESHOLD);
  }

  // Returns true if the array contains only empty values
  static isEmpty(array) {
    if (!array || array.length === 0) return true;

    // Are there any false values? If so return false;
    return array.filter(x => x.length > 0).length == 0;
  }

  // Returns true if the array contains integers
  static isInteger(array) {
    if (!array || array.length === 0) return false;

    let testResults = array.map(x => (
      x.trim().indexOf(' ') == -1 &&
      !isNaN(parseInt(x)) && 
      x.indexOf('.') == -1
    ));

    return Typer.validateTestResults(testResults, array);
  }

  // Returns true if the array contains booleans
  static isBoolean(array) {
    if (!array || array.length === 0) return false;

    let testResults = array.map(x => x.trim().toUpperCase() === "true".toUpperCase() || x.trim().toUpperCase() === "false".toUpperCase());

    return Typer.validateTestResults(testResults, array);
  }

  static CURRENCY_REGEX = /^[\$\€\¥\£]+[0-9\,]+\.?[0-9]*$/i;
  // Returns true if the array contains currency values
  static isCurrency(array) {
    if (!array || array.length === 0) return false;

    let testResults = array.map(x => Typer.CURRENCY_REGEX.test(x));

    return Typer.validateTestResults(testResults, array);
  }

  static DECIMAL_REGEX = /^[0-9\,]+\.?[0-9]*\%?$/i;
  // Returns true if the array contains decimals
  static isDecimal(array) {
    if (!array || array.length === 0) return false;

    // if all entries are zero we have to assume the field is decimal
    if (array.filter(val => val == "0").length == array.length) return true;

    let testResults = array.map(x => Typer.DECIMAL_REGEX.test(x));

    return Typer.validateTestResults(testResults, array);
  }

  // Returns true if the array contains SQL dates
  static SQL_DATE_REGEX = /\d{4,}-\d{2,}-\d{2,}/i;
  static isSQLDate(array) {
    if (!array || array.length === 0) return false;

    let testResults = array.map(x => Typer.SQL_DATE_REGEX.test(x));

    return Typer.validateTestResults(testResults, array);
  }

  // Returns true if the array contains date times
  static GREGORIAN_DATE_REGEX = /\d+\/\d+\/\d+/i;
  static isDate(array) {
    if (!array || array.length === 0) return false;

    let testResults = array.map(x => Typer.GREGORIAN_DATE_REGEX.test(x));

    return Typer.validateTestResults(testResults, array);
  }

  // Returns the maximum number of characters from the values in an array
  static maxChars(array) {
    if (!array || array.length === 0) return 0;

    let testResults = array.map(x => x.length);
    let max = Math.max.apply(null, testResults);

    // Round up to the next multiple of 5;
    return Math.ceil(max / 5) * 5;
  }
}

export default Typer;