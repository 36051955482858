import { useEffect, useContext, useState } from "react";
import {
  Link, useNavigate
} from "react-router-dom";

// contexts
import { CSVContext } from "../../Contexts/CSVContext.js";
import { IssuesContext } from "../../Contexts/IssuesContext.js";

// utils
import DDLBuilder from "../../Util/DDLBuilder/DDLBuilder.js"
import CSVBuilder from "../../Util/CSVBuilder/CSVBuilder.js"
import useVerifyUser from "../../Session/VerifyUser.js";

// Trigger the CSV file download
function DownloadCSV(currentCSV) {
  const contents = CSVBuilder.build(currentCSV);

  const element = document.createElement("a");
  const file = new Blob([contents], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = currentCSV.fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

// Trigger the DDL file download
function DownloadDDL(currentCSV, databaseType) {
  const contents = DDLBuilder.create(currentCSV, databaseType);

  const element = document.createElement("a");
  const file = new Blob([contents], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = DDLBuilder.getDDLName(currentCSV);
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

// View for downloading finished files
function DownloadView() {
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);
  const [currentIssues, setCurrentIssues] = useContext(IssuesContext);
  const [databaseType, setDatabaseType] = useState(DDLBuilder.databases.PostgresQL);

  useVerifyUser();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-6 offset-1 p-4">
          <div className="h1">Repair Successful: <span className="font-monospace h2">{currentCSV.fileName}</span> <Link to="/fix" className="ms-5 btn btn-outline-secondary btn-sm">Go Back to Editor</Link></div>
          {currentCSV.rows ?
            <div className="small text-muted">Rows: {currentCSV.rows.length}</div>
            : <></>}
          {currentCSV.columns ?
            <div className="small text-muted">Columns: {currentCSV.columns.length}</div>
            : <></>}
          <div className="small text-muted">Encoding: {currentCSV.encoding}</div>
          <div className="row mb-5">
            <div className="col-12 mb-5 p-3 text-center d-grid gap-2">
              <button type="button" className="btn btn-success btn-lg" onClick={() => DownloadCSV(currentCSV)}>Download Repaired File</button>
            </div>
          </div>
          <div className="row">
            <div className="card card-body">
              <h5 className="card-title">SQL Support</h5>
              <select className="form-select" aria-label="Database Type" value={databaseType} onChange={e => setDatabaseType(e.target.value)} >
                {DDLBuilder.getAllDatabases().map(d =>
                  <option value={d} key={"o_" + d}>{d}</option>
                )}
              </select>
              <div className="col-12 p-3 d-grid gap-2">
                If you plan to load this CSV into a {databaseType} database, you can download the DDL file to create a compatible SQL table here:
                <button type="button" className="btn btn-outline-secondary" onClick={() => DownloadDDL(currentCSV, databaseType)}>Download DDL</button>
              </div>
              <div className="col-12 p-3 d-grid gap-2">
                You can run the following command to import your CSV into the table after you've created it:
                <pre className="chroma"><code className="language-sql" data-lang="sql">
                  {DDLBuilder.generateLoadingCommand(currentCSV, databaseType)}
                </code></pre>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 mt-5 p-3 text-center d-grid gap-2">
              <Link type="button" className="btn btn-secondary btn-lg" to="/upload">Repair Another File</Link>
            </div>
          </div>
        </div>
        <div className="col-4 offset-1 border-start vh-100">
          <div className="row mb-5">
            <div className="col-12 align-middl m-2">
              <div className="h5 mt-3">Corrections Made:</div>
              <div className="">
                {(currentIssues && currentIssues.fixed) ?
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-sm">
                        <tbody>
                          {currentIssues.fixed.getAll().map((entry, i) => (
                            <tr key={Math.random()}>
                              <td>
                                {entry.type}: {entry.issues.length} fixed
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <></>}
              </div>

              <div className="h5 mt-3">Errors Ignored:</div>
              <div className="">
                {(currentIssues && currentIssues.open) ?
                  <div className="row">
                    <div className="col-12">
                      <table className="table table-sm">
                        <tbody>
                          {currentIssues.open.getAll().map((entry, i) => (
                            <tr key={Math.random()}>
                              <td>
                                {entry.type}: {entry.issues.length}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <></>}
              </div>
            </div>
          </div >
        </div>
      </div >
    </div >
  );
}

export default DownloadView;