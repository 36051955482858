import CSVIssue from "../../CSVIssue/CSVIssue";
import TemplateColDetector from "./TemplateColDetector";

class GUIDDetector extends TemplateColDetector {
    constructor() {
        super();
        this.detectorType = CSVIssue.MISSING_DATES;
    }

    static MINIMUM_ROWS = 25;

    analyze(csv, col) {
        let issues = [];

        // Calculate the entropy of the column
        let valueMap = new Map();
        let rowCount = csv.rows.length;
        if (rowCount > GUIDDetector.MINIMUM_ROWS) {
            for (let row in csv.rows) {
                let value = csv.rows[row][col];
                // if any of the values have spaces, it's not a GUID
                if(!value || typeof value !== 'string' || value.indexOf(' ') !== -1) {
                    return null;
                }

                // insert into the map
                if (valueMap.has(value)) valueMap.set(value, valueMap.get(value) + 1);
                else valueMap.set(value, 1);
            }

            // how many unique values do we have?
            let valueCount = valueMap.size;

            // entropy 
            let entropy = this.calculateEntropy(valueCount, rowCount);

            // if we found one, set it as a GUID
            if (entropy == 1.0) {
                csv.columns[col].guid = true;
            }
        }

        return null;
    }

    calculateEntropy(values, rows) {
        return values / rows;
    }
}

export default GUIDDetector;