import React, { createContext, useState } from "react";

import CSVIssueMap from "../Util/CSVIssueMap/CSVIssueMap";

// Context for storing issues found in the CSV
const IssuesContext = createContext({});
const IssuesContextProvider = ({ children }) => {
  const [currentIssues, setCurrentIssues] = useState({
    open: new CSVIssueMap({}),
    fixed: new CSVIssueMap({})
  });

  return (
    <IssuesContext.Provider value={[currentIssues, setCurrentIssues]}>
      {children}
    </IssuesContext.Provider>
  );
};

export { IssuesContext, IssuesContextProvider };