function WarningModal({ toClose }) {
  return (
    <div className="container-fluid notificationModalStyling">
      <div className="row">
        <div className="col-12 align-middl m-2 text-center">
          <h2 id="notification-modal-title">Only CSV Files</h2>
          <p id="notification-modal-description">
            Spreadsheet.ai only supports CSV (comma-separated value) plain text files.
            The file you provided was not a CSV, please select a CSV file to proceed.
          </p>
          <button type="button" className="btn btn-success" onClick={() => toClose()}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default WarningModal;