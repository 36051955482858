import FieldTypes from "../FieldTypes";

class CSVIssue {
    static MISSING_VALUES = "Missing Values";
    static MISSING_COLUMNS = "Missing Columns";
    static MISSING_DELIMITER = "Missing Delimiter";
    static MISSING_DATES = "Missing Dates";
    static BAD_VALUES = "Bad Values";
    static BAD_ESCAPING = "Bad Escaping";
    static BAD_TYPE = "Bad Type";
    static BAD_DATE = "Bad Date";
    static BYTE_CODE_OFFSET_ERROR = "Byte Code Offset Error";
    static INTERNAL_ERROR = "Internal Error";

    static MAJOR_ISSUE = 2;
    static MINOR_ISSUE = 1;

    constructor({type = CSVIssue.INTERNAL_ERROR, column = "", dataType = "", cells = []}) {
        this.id = Math.random();
        this.type = type;
        this.column = column;
        this.dataType = dataType;
        this.cells = cells;
      }

    removeCell(row, col) {
      const iRow = parseInt(row);
      const iCol = parseInt(col);
      this.cells = this.cells.filter(cell => cell.row !== iRow || cell.col !== iCol);
    }

    static getAllIssueTypes() {
      return [
        CSVIssue.MISSING_VALUES,
        CSVIssue.MISSING_COLUMNS,
        CSVIssue.MISSING_DELIMITER,
        CSVIssue.MISSING_DATES,
        CSVIssue.BAD_VALUES,
        CSVIssue.BAD_ESCAPING,
        CSVIssue.BAD_TYPE,
        CSVIssue.BAD_DATE,
        CSVIssue.BYTE_CODE_OFFSET_ERROR,
        CSVIssue.INTERNAL_ERROR
      ];
    }

    static severity(type) {
      if([CSVIssue.BAD_VALUES, CSVIssue.BAD_TYPE, CSVIssue.MISSING_DATES].includes(type)) {
        return CSVIssue.MAJOR_ISSUE;
      }

      return CSVIssue.MINOR_ISSUE;
    }
  
    static generateTestIssue() {
          let testIssue = new CSVIssue();
          testIssue.type = CSVIssue.BAD_VALUES;
          testIssue.column = "first";
          testIssue.dataType = FieldTypes.TEXT;
          testIssue.cells = [{row: 1, col: 1}];
          return testIssue;
      }
}

export default CSVIssue;