import CSVIssue from "../../CSVIssue/CSVIssue";

class TemplateColDetector {
    analyze(csv, col) {
        let issue = new CSVIssue({ type: this.detectorType });
        issue.column = csv.columns[col].name;
        issue.dataType = csv.columns[col].type;

        // look for problems
        for (let row in csv.rows) {
            let value = csv.rows[row][col];
            if (this.test(value, issue.dataType)) {
                issue.cells.push({ row: parseInt(row), col: parseInt(col) });
            } 
        }

        if (issue.cells && issue.cells.length > 0) {
            return [issue];
        } else return null;
    }

    test(value, expectedType) {
        throw new Error("Detector did not implement test()");
    }
}

export default TemplateColDetector;