import ColumnList from "./ColumnList";

function MySQLLoadStatement({ csv, tableName, headerFormatter }) { 
    return (
      <>
LOAD DATA INFILE '{csv.fileName}'<br/>
INTO TABLE {tableName} <br/>
FIELDS TERMINATED BY ',' <br/>
ENCLOSED BY '"'<br/>
LINES TERMINATED BY '\n'<br/>
IGNORE 1 ROWS<br/>
(<ColumnList csv={csv} headerFormatter={headerFormatter}/>);
      </>
    );
  }
  
  export default MySQLLoadStatement;