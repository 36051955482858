import { useContext, useEffect, useRef, useState, useMemo } from "react";
import {
  useNavigate, useLocation
} from "react-router-dom";
import './FixingPage.css';

// Popover
import Popover, { PopoverVirtualElement } from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// components
import TypeSelector from '../../Components/Widgets/TypeSelector';
import IssueCard from '../../Components/Widgets/IssueCard';

// contexts
import { IssuesContext } from "../../Contexts/IssuesContext.js";
import { CSVContext } from "../../Contexts/CSVContext.js";

// utils
import FixPopover from "./FixPopover";
import CSVIssue from "../../Util/CSVIssue/CSVIssue";
import CSVIssueMap from "../../Util/CSVIssueMap/CSVIssueMap";
import FixModal from "./FixModal.js";
import NotificationModal from "./NotificationModal.js";
import useVerifyUser from "../../Session/VerifyUser.js";
import BrowserUtil from "../../Util/BrowserUtil/BrowserUtil.js";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function getTypeClass(type) {
  if (CSVIssue.severity(type) == CSVIssue.MAJOR_ISSUE) {
    return "majorIssue";
  }

  return "minorIssue";
}

const MAX_ROWS_TO_SHOW = 5000;
const POPOVER_DELAY = 1000;
function FixingView() {
  const query = useQuery();
  const notification = query.get("n");
  const [currentCSV, setCurrentCSV] = useContext(CSVContext);
  const [currentIssues, setCurrentIssues] = useContext(IssuesContext);
  const gridRef = useRef(null);
  const [activeCells, setActiveCells] = useState([]);

  const [openChartModal, setOpenChartModal] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  const [openFixPopover, setOpenFixPopover] = useState(false);
  const [anchorElFixPopover, setAnchorElFixPopover] = useState({ getBoundingClientRect: () => DOMRect.fromRect(), nodeType: 1 });
  const [currentCell, setCurrentCell] = useState([]);
  const [currentIssue, setCurrentIssue] = useState(null);

  // const handleOpenChartModal = () => {
  //   setOpenChartModal(true);
  // }
  useVerifyUser();

  useEffect(() => {
    if (notification) {
      setOpenNotificationModal(true);
    }
  }, [notification]);

  const handleCloseChartModal = () => {
    setOpenChartModal(false);
  }
  const closeFixModal = () => { }

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  }
  const closeNotificationModal = () => { setOpenNotificationModal(false); }

  const closeFixPopover = (fixed = false) => {
    setOpenFixPopover(false);
    if (fixed) {
      unhighlightCell(currentCell.row, currentCell.col);

      // remove this cell from the issue
      currentIssue.removeCell(currentCell.row, currentCell.col);

      if (currentIssue.cells.length === 0) {
        ignoreIssue(currentIssue);
      }
    }
  };

  const cellSelect = (ev) => {
    let el = document.elementFromPoint(ev.clientX, ev.clientY);
    cellSelectEl(el);
  }

  const cellSelectEl = (el) => {
    if (el.tagName === "TD") {
      // get ID
      let cellCoords = el.id.split('_');
      let cell = currentCSV.rows[cellCoords[0]][cellCoords[1]];
      setCurrentCell(
        {
          row: parseInt(cellCoords[0]),
          col: parseInt(cellCoords[1]),
          val: cell
        }
      );

      const getBoundingClientRect = () => {
        return el.getBoundingClientRect();
      };

      setAnchorElFixPopover({ getBoundingClientRect, nodeType: 1 });
      setOpenFixPopover(true);
    }
  };

  // highlights a cell in the table
  function highlightCells(cells) {
    cells.map((c) => {
      highlightCell(c.row, c.col)
    });
  }
  function highlightCell(row, col) {
    const cell = gridRef.current.rows[row + 2].cells[col + 1];
    if (cell) {
      cell.classList.add('highlighted-cell');
      cell.addEventListener("click", cellSelect, false);
    }
  }
  function unhighlightCells(cells) {
    cells.map((c) => {
      unhighlightCell(c.row, c.col)
    });
  }
  function unhighlightCell(row, col) {
    const cell = gridRef.current.rows[row + 2].cells[col + 1];
    if (cell) {
      cell.classList.remove('highlighted-cell');
      cell.removeEventListener("click", cellSelect, false);
    }
  }

  // Activates fixing interface
  const fixIssue = (issue) => {
    setCurrentIssue(issue);

    if (issue.type == CSVIssue.MISSING_DATES) {
      fixModalIssue(issue);
    } else {
      fixPopoverIssue(issue);
    }
  }
  // Fix issues using the modal
  const fixModalIssue = (issue) => {
    setOpenChartModal(true);
  }
  // Fix issues using the popover
  const fixPopoverIssue = (issue) => {
    unhighlightCells(activeCells);
    highlightCells(issue.cells);
    setActiveCells(issue.cells);

    const firstElementId = issue.cells[0].row + "_" + issue.cells[0].col;
    const element = document.getElementById(firstElementId);
    console.log("Selected element: " + element["id"]);
    if (element) {
      console.log("Scrolling ...")
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });

      if (BrowserUtil.isChromium()) {
        // Chrome has a weird bug with scrollIntoView, so we have to try twice to ensure it happens
        setTimeout(function () {
          element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });

          // Open up the popover view
          setTimeout(function () {
            cellSelectEl(element);
          }, POPOVER_DELAY); // wait for the scroll to happen before opening it
        }, POPOVER_DELAY); // wait for the scroll to happen before opening it
      } else {
        // Open up the popover view
        setTimeout(function () {
          cellSelectEl(element);
        }, POPOVER_DELAY); // wait for the scroll to happen before opening it
      }
    }
  }

  // Moves an issue to the Fixed state
  const ignoreIssue = (issue) => {
    setCurrentIssue(null);
    unhighlightCells(activeCells);
    setActiveCells(activeCells);

    let newOpen = new CSVIssueMap({ issues: currentIssues.open.issueMap, types: currentIssues.open.types });
    newOpen.remove(issue);
    let newFixed = new CSVIssueMap({ issues: currentIssues.fixed.issueMap, types: currentIssues.fixed.types });
    newFixed.add(issue);

    setCurrentIssues(
      {
        open: newOpen,
        fixed: newFixed
      })
  }

  const navigate = useNavigate();
  const done = () => {
    navigate("/download");
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-9 align-middl m-2 overflow-auto" id="grid-table-container">
            {/* Spreadsheet grid view */}
            <div className="table-responsive overflow-auto vh-100">
              <table className="table table-sm" ref={gridRef}>
                <thead>
                  <tr>
                    <th></th>
                    {currentCSV.columns ? currentCSV.columns.map((c, i) => (
                      <th scope="col" className="text-muted" key={"t" + i}><TypeSelector selectedIndex={i} /></th>
                    )) : <></>}
                  </tr>
                </thead>

                <thead className="table-light table-header">
                  <tr>
                    <th>#</th>
                    {currentCSV.columns ? currentCSV.columns.map((col) => (
                      <th scope="col" key={"h" + col.name}>
                        <div style={{ display: "flex" }} >
                          {col.name}{ /** &nbsp;<i className="bi bi-graph-up text-primary" onClick={handleOpenChartModal}></i>**/}
                        </div>
                      </th>
                    )) : <></>}
                  </tr>
                </thead>

                <tbody>
                  {currentCSV.rows ? currentCSV.rows.slice(0, MAX_ROWS_TO_SHOW).map((row, r) => (
                    <tr key={"r" + r}>
                      <th scope="row">{r}</th>
                      {row.map((cell, c) => (
                        currentCSV.columns[c].hidden ?
                          (<td id={r + "_" + c} key={"c" + r + "_" + c} className="data-cell table-active">{cell}</td>) :
                          (<td id={r + "_" + c} key={"c" + r + "_" + c} className="data-cell">{cell}</td>)
                      ))}
                    </tr>
                  )) : <></>}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-2 align-middle">
            <div className="row mb-5 border-bottom">

              <div className="col-12 align-middl m-2">
                <div className="font-monospace h2">{currentCSV.fileName}</div>
                <div className="small text-muted">Encoding: {currentCSV.encoding}</div>
                {currentCSV.rows ?
                  <div className="small text-muted">Rows: {currentCSV.rows.length}</div>
                  : <></>}
                {currentCSV.columns ?
                  <div className="small text-muted">Columns: {currentCSV.columns.length}</div>
                  : <></>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 align-middl">
                {/* Issues view */}
                <>
                  {(currentIssues && currentIssues.open && currentIssues.open.types.length > 0) ?
                    <>
                      <div className="row">
                        <div className="col-12">
                          <b>I need your help to fix these issues...</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="accordion" id="issuesAccordion">
                            {currentIssues.open.getAll().map((entry, i) => (
                              <div className="accordion-item" key={"accordion_" + i}>
                                <h2 className="accordion-header" id={'heading_' + i}>
                                  <button className={`${getTypeClass(entry.type)} accordion-button collapsed`} type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_' + i} aria-expanded="false" aria-controls={'collapse_' + i}>
                                    {entry.type} error ({entry.issues.length} columns)
                                  </button>
                                </h2>
                                <div id={'collapse_' + i} className="accordion-collapse collapse" aria-labelledby={'heading_' + i} data-bs-parent="#issuesAccordion">
                                  <div className="accordion-body">
                                    <ul className="list-group list-group-flush">
                                      {entry.issues.map((issue) => (
                                        <IssueCard issue={issue} key={Math.random()} fixer={fixIssue} ignorer={ignoreIssue} />
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                    : <></>}
                  <div className="row mt-3">
                    <div className="col-12">
                      <b>Fixed</b>
                    </div>
                  </div>

                  {(currentIssues && currentIssues.fixed) ?
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-sm">
                          <tbody>
                            {currentIssues.fixed.getAll().map((entry, i) => (
                              <tr key={Math.random()}>
                                <td>
                                  {entry.type}: {entry.issues.length} errors fixed
                                </td>
                              </tr>
                            ))}
                            {currentIssues.fixed.getCount() === 0 ?
                              <tr>
                                <td>
                                  None
                                </td>
                              </tr>
                              : <></>}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    : <></>}
                </>

                <div className="row mt-3">
                  <div className="col-12 d-grid gap-2">
                    <button type="button" className="btn btn-outline-success" onClick={done}>Done</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={openChartModal}
          onClose={handleCloseChartModal}
          aria-labelledby="fix-modal"
          aria-describedby="fix-modal-description"
        >
          <div><FixModal issue={currentIssue} toClose={closeFixModal} /></div>
        </Modal>
        <Modal
          open={openNotificationModal}
          onClose={handleCloseNotificationModal}
          aria-labelledby="notification-modal-title"
          aria-describedby="notification-modal-description"
        >
          <div><NotificationModal notify={notification} toClose={closeNotificationModal} /></div>
        </Modal>
      </div>

      <Popover
        id="fix-cell-popover"
        open={openFixPopover}
        anchorEl={anchorElFixPopover}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div><FixPopover issue={currentIssue} cell={currentCell} toClose={closeFixPopover} /></div>
      </Popover>
    </>
  );
}

export default FixingView;