import { useState, useContext } from "react";
import {
    useNavigate
} from "react-router-dom";
import './LoginPage.css';

// contexts
import { UserContext } from "../../Contexts/UserContext";

const PASSWORD = "csvsaretheworst";

function LoginPage() {
    const [user, setUser] = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Login the user
    const navigate = useNavigate();
    const finishLogin = () => {
        setUser({
            email: email
        });

        navigate("/upload");
    }
    const doLogin = () => {
        // check if email and password match
        if(password === PASSWORD) {
            finishLogin();
        } else {
            setError({message:"Incorrect email or password"})
        }
    }

    // watch for Enter press
    const monitorTypingKey = (e) => {
        if (e.key === 'Enter') {
            doLogin();
        }
    }

    return (
        <div className="login-form text-center vh-100">
            <div className="form-signin">
                <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                {(error ? (
                    <div className="alert alert-danger" role="alert">
                        {error.message}
                    </div>
                ) : <></>)}
                <input type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    autoFocus
                    value={email}
                    onKeyDown={monitorTypingKey}
                    onChange={e => {
                        setEmail(e.target.value)
                    }} />
                <input type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    value={password}
                    onKeyDown={monitorTypingKey}
                    onChange={e => {
                        setPassword(e.target.value)
                    }} />
                <button className="btn btn-lg btn-success btn-block" type="submit" onClick={doLogin}>Sign in</button>
                <p className="mt-5 mb-3 text-muted">Spreadsheet.ai 2024</p>
            </div>
        </div>
    );
}

export default LoginPage;