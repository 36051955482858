import React, { createContext, useState } from "react";

// Context for storing the raw binary data from the file.
const RawFileContext = createContext({});
const RawFileContextProvider = ({ children }) => {
  const [currentFile, setCurrentFile] = useState({});

  return (
    <RawFileContext.Provider value={[currentFile, setCurrentFile]}>
      {children}
    </RawFileContext.Provider>
  );
};

export { RawFileContext, RawFileContextProvider };