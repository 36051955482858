import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';


function DateChart({data}) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        TimeScale
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
        },
        scales: {
            x: {
              type: 'time'
            },
            y: {
              title: {
                display: true,
                text: '# of Rows'
              },
              ticks: {
                precision: 0
              }
            }
          }
    };

    let labels = [];
    let dataPoints = [];
    for(let e of data) {
        labels.push(e[0]);
        dataPoints.push(e[1])
    }

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Dates',
                data: dataPoints,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (<Bar options={options} data={chartData} />);
}

export default DateChart;
