import {
  Link
} from "react-router-dom";

// Header for the site
function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark brand-bg">
    <div className="container-fluid">
      <Link to="/upload" className="navbar-brand logo-title">Spreadsheet.ai</Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
    </div>
  </nav>
  );
}

export default Header;